<bit-section>
  <bit-section-header>
    <h2 bitTypography="h6">{{ "customFields" | i18n }}</h2>
  </bit-section-header>
  <bit-card>
    <div
      class="tw-border-secondary-300 [&_bit-form-field:last-of-type]:tw-mb-0"
      *ngFor="let field of cipher.fields; let last = last"
      [ngClass]="{ 'tw-mb-4': !last }"
      data-testid="custom-field"
    >
      <bit-form-field *ngIf="field.type === fieldType.Text" [disableReadOnlyBorder]="last">
        <bit-label>{{ field.name }}</bit-label>
        <input readonly bitInput type="text" [value]="field.value" aria-readonly="true" />
        <button
          bitIconButton="bwi-clone"
          bitSuffix
          type="button"
          [appCopyClick]="field.value"
          showToast
          [valueLabel]="field.name"
          [appA11yTitle]="'copyCustomField' | i18n: field.name"
          data-testid="copy-custom-field"
        ></button>
      </bit-form-field>
      <bit-form-field *ngIf="field.type === fieldType.Hidden" [disableReadOnlyBorder]="last">
        <bit-label>{{ field.name }}</bit-label>
        <input readonly bitInput type="password" [value]="field.value" aria-readonly="true" />
        <button
          bitSuffix
          type="button"
          bitIconButton
          bitPasswordInputToggle
          (toggledChange)="logHiddenEvent($event)"
        ></button>
        <button
          bitIconButton="bwi-clone"
          bitSuffix
          type="button"
          [appCopyClick]="field.value"
          showToast
          [valueLabel]="field.name"
          [appA11yTitle]="'copyCustomField' | i18n: field.name"
          (click)="logCopyEvent()"
        ></button>
      </bit-form-field>
      <bit-form-control *ngIf="field.type === fieldType.Boolean">
        <input
          bitCheckbox
          type="checkbox"
          [checked]="field.value === 'true'"
          aria-readonly="true"
          disabled
        />
        <bit-label> {{ field.name }} </bit-label>
      </bit-form-control>
      <bit-form-field *ngIf="field.type === fieldType.Linked" [disableReadOnlyBorder]="last">
        <bit-label> {{ "cfTypeLinked" | i18n }}: {{ field.name }} </bit-label>
        <input
          readonly
          bitInput
          type="text"
          [value]="getLinkedType(field.linkedId)"
          aria-readonly="true"
        />
      </bit-form-field>
    </div>
  </bit-card>
</bit-section>
