<bit-section>
  <bit-section-header>
    <h2 bitTypography="h6">{{ setSectionTitle }}</h2>
  </bit-section-header>
  <read-only-cipher-card>
    <bit-form-field *ngIf="card.cardholderName">
      <bit-label>{{ "cardholderName" | i18n }}</bit-label>
      <input
        readonly
        bitInput
        type="text"
        [value]="card.cardholderName"
        aria-readonly="true"
        data-testid="cardholder-name"
      />
    </bit-form-field>
    <bit-form-field *ngIf="card.number">
      <bit-label>{{ "number" | i18n }}</bit-label>
      <input
        readonly
        bitInput
        type="password"
        [value]="card.number"
        aria-readonly="true"
        data-testid="cardholder-number"
      />
      <button
        bitSuffix
        type="button"
        bitIconButton
        bitPasswordInputToggle
        data-testid="toggle-number"
        (toggledChange)="logCardEvent($event, EventType.Cipher_ClientToggledCardNumberVisible)"
      ></button>
      <button
        bitIconButton="bwi-clone"
        bitSuffix
        type="button"
        [appCopyClick]="card.number"
        showToast
        [valueLabel]="'number' | i18n"
        [appA11yTitle]="'copyNumber' | i18n"
        data-testid="copy-number"
      ></button>
    </bit-form-field>
    <bit-form-field *ngIf="card.expiration">
      <bit-label>{{ "expiration" | i18n }}</bit-label>
      <input
        readonly
        bitInput
        type="text"
        [value]="card.expiration"
        aria-readonly="true"
        data-testid="cardholder-expiration"
      />
    </bit-form-field>
    <bit-form-field *ngIf="card.code">
      <bit-label>{{ "securityCode" | i18n }}</bit-label>
      <input
        readonly
        bitInput
        type="password"
        [value]="card.code"
        aria-readonly="true"
        data-testid="cardholder-code"
      />
      <button
        bitSuffix
        type="button"
        bitIconButton
        bitPasswordInputToggle
        data-testid="toggle-code"
        (toggledChange)="logCardEvent($event, EventType.Cipher_ClientToggledCardCodeVisible)"
      ></button>
      <button
        bitIconButton="bwi-clone"
        bitSuffix
        type="button"
        [appCopyClick]="card.code"
        showToast
        [valueLabel]="'securityCode' | i18n"
        [appA11yTitle]="'copySecurityCode' | i18n"
        data-testid="copy-code"
        (click)="logCardEvent(true, EventType.Cipher_ClientCopiedCardCode)"
      ></button>
    </bit-form-field>
  </read-only-cipher-card>
</bit-section>
