<bit-section>
  <bit-section-header>
    <h2 bitTypography="h6">{{ "additionalOptions" | i18n }}</h2>
  </bit-section-header>
  <bit-card class="[&_bit-form-field:last-of-type]:tw-mb-0">
    <bit-form-field disableReadOnlyBorder>
      <bit-label>{{ "note" | i18n }}</bit-label>
      <textarea readonly bitInput aria-readonly="true">{{ notes }}</textarea>
      <button
        bitSuffix
        bitIconButton="bwi-clone"
        size="small"
        type="button"
        [appCopyClick]="notes"
        showToast
        [valueLabel]="'note' | i18n"
        [appA11yTitle]="'copyNotes' | i18n"
      ></button>
    </bit-form-field>
  </bit-card>
</bit-section>
